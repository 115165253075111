<template>
    <div class="main">
      <nav class="navbar navbar-light">
        <div class="container-fluid col-12">
            <div class="navbar-brand"><img src="@/assets/logo.png" alt="HOME" title="HOME"></div>
            
              <div class="d-flex my-2 mx-2 my-lg-0">
                <div class="dropdown">
                   <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
                    <span class="control">
                        <a class="button">
                         <b-icon icon="person-fill"> </b-icon>
                          {{ init.profile.fullName }}
                        </a>   
                    </span><span class="caret"></span></button>
                    <div class="dropdown-content">
                        <router-link :to="'/txhistory/' + userId"><div class="a-text">Transaction History </div></router-link>
                        <a href="#" class="a-text" v-on:click="openLogout">Log out </a>
                    </div>
                </div>
              </div>
          </div>
          </nav>


      <div class="body">
        <h1 class="my-5 text-white">My Cart</h1>
        <div class="container product-cart">
          <div class="d-modal row">
            <div class="cart-header modal-header">
              <div class="cart-title modal-title">
              <!-- <Timer/> -->
              </div>
            </div>

            <form v-on:submit.prevent="proceedToCheckout()">
              <div v-if="cart" class="container mb-3 text-center">
                <div
                  class="row g-0 mx-auto d-flex justify-content-between cardCart"
                >
                  <div class="col-md-6 mt-2">
                    <img
                     :src="cart.image"
                      class="store-img"
                    />
                    <p class="cart-text text-center">
                      <small class="text-muted"
                        ><strong>{{cart.product}}</strong></small
                      >
                    </p>
                  </div>
                  <div class="col-md-6 card-body mt-2">
                    <h5 class="cart-text">Locked Price</h5>
                    <p class="cart-text">
                      <small class="text-muted cart-text"
                        >1 <strong>{{cart.product}}</strong> =
                        <strong class="cart-texts"> PHP {{ formatAmount(cart.price)}}</strong></small
                      >
                    </p>
                  </div>
                </div>
                <div class="row g-0 mx-auto d-flex justify-content-between">
                  <div class="enterAmount text-center col-md-4 form-control">
                    <p class="danger" v-if="this.errors.amountToBuy != ''">{{errors.amountToBuy}}</p>
                    <p>
                      <small class="error-text">{{showTotalAmountError}}</small>
                    </p>
                    <h5 class="cart-text">
                      Enter amount to BUY in <strong>{{cart.fromCurrency}}</strong
                      >:
                    </h5>
                    <b-input
                      v-model.number="amountToBuy"
                      type="text"
                      required
                      class="form-control text-center cart-text"
                      name="amountToBuy"
                      placeholder="Enter amount to buy"
                      maxlength="6"
                      @keypress="isNumber($event)"
                    />
                    <p class="cart-text text-center">
                      <small class="text-muted">Fee: {{cart.fee}} {{cart.fromCurrency}}</small>
                    </p>
                  </div>
                  

                  <div class="col-md-5 card-body">
                    <h5 class="cart-text text-center">
                      You will receive in <strong>{{cart.fromCurrency}}</strong
                      >:
                    </h5>
                     <h5 class="form-control cart-text"> {{cryptoToReceive}} </h5>
                    <p>
                      <small class="text-muted"></small>
                    </p>
                  </div>

                  <div class="col-md-2 text-center">
                    <img src="@/assets/images/arrow.png" class="store-img" />
                  </div>

                  <div class="col-md-5 card-body text-dark">
                    <h5 class="cart-text text-center">
                      You will pay this amount in <strong>{{cart.toCurrency}}</strong>:
                    </h5>
                    <h5 class="form-control cart-text"> {{formatAmount(totalAmountFiat)}}</h5>
                    
                    
                  </div>
                </div>

                <hr/>
              </div>
              <div v-else>
                <b-icon
                  icon="arrow-clockwise"
                  animation="spin"
                  font-scale="4"
                ></b-icon
                >Getting your quote....
              </div>

              <div id="cart-buttons">
                <b-button class="btn btn-secondary mb-2 cancel" @click="exitModal">Cancel
                </b-button>
                <b-button class="btn btn-primary mb-2 submit" type="submit">
                  Proceed to checkout
                </b-button>
              </div>
            </form>
          </div>
      </div>
      </div>
      <Exit @close="exitModal" v-if="showExitModal"/>
      <PriceReset v-if="lockedPriceReset"/>
      <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
      <Kyc v-if="completeKYC"/>
      <Footer/>
      <ContactUs @close="close" v-if="showContact" />

      </div>
    </template>
    
    <script>
      import Footer from '../views/Footer.vue'
      import numeral from 'numeral'
      import Exit from '../components/modals/Exit.vue'
      import PriceReset from '../components/modals/PriceReset.vue'
      import LogoutModal from '../components/modals/LogoutModal.vue'
      import Timer from "../components/modals/Timer.vue"
      import Kyc from "../components/modals/Kyc.vue"
      import ContactUs from '../components/modals/ContactUs.vue'

      export default {
          name: 'Cart',
          components: {
              Footer,
              Exit,
              PriceReset,
              Timer,
              LogoutModal,
              Kyc,
              ContactUs
          },
          data(){
              return{
                  lockedPriceReset: false,
                  createdAt:'',
                  userId: '',
                  showCartModal: false,
                  cartTimerSeconds: 450,
                  amountToBuy: 0.05,
                  errors: {
                    amountToBuy:'',
                  },
                  showExitModal: false,
                  showTotalAmountError: '',
                  isEditing: false,
                  timer:'',
                  showLogoutModal: false,
                  completeKYC: false,
                  profile:{
                    mobileNumber:'',
                    middleName:'',
                    birthDate:'',
                    emailAddress:''

                  },
                  showContact: false,
                  status:''
                  
                  

              }
          },
          computed: {
              init() {
                  return this.$store.state.init;
              },
               cart() {
                  return this.$store.state.cart;
                },
                cartItems() {
                  return this.$store.state.cartItems;
                },
                cryptoToReceive: function () {
                  return this.amountToBuy - this.cart.fee;
                },
                totalAmountFiat: function () {
                  return this.cryptoToReceive * this.cart.price;
                 
                },
                
          },
          created: function(){
              let loaded = JSON.parse(localStorage.getItem('cart'));

              if(loaded) {
                this.cart.product = loaded.productId;
                this.cart.fromCurrency = loaded.product.fromCurrency;
                this.cart.toCurrency = loaded.product.toCurrency;
                this.cart.image = loaded.image;
                this.cart.price = loaded.product.convertedAmount;
                this.cart.fee = loaded.fee;
                }
                console.log(localStorage.getItem('cart'))
            },
         
          methods:{
            openLogout(){
              this.showLogoutModal = !this.showLogoutModal;
            },

            exitModal() {
              this.showExitModal = !this.showExitModal;
            },
            close() {
              this.showContact = !this.showContact;
            },
           
            
            getProfile: function(){
                const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                url: '/cx/profile/',
              };

                this.axios(options)
                  .then((response) => {
                     console.log(response.data)
                    this.init.profile = response.data;
                    this.userId = response.data.entityId;
                    this.profile.middleName = response.data.middleName
                    this.profile.mobileNumber = response.data.mobilePhone
                    this.profile.emailAddress = response.data.emailAddress
                    this.profile.birthDate = response.data.birthDate
                    this.status = response.data.verificationStatus

                    })
                   
            },
            checkSession: function() {

                const options = {
                  method: 'POST',
                  headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                  url: '/cx/sessioncheck',
                };

                this.axios(options)
                  .then((response) => { 
                    this.getProfile();
                      setTimeout( () => {
                        this.showLoading = false;
                      },1000)
                    }).catch((err) => {
                        
                      if(err.response.data.msgCode == '00001') {
                        window.location.href = "/#/";
                      }  

                      if(err.response.data.msgCode == '00020') {

                        setTimeout( () => {
                        window.location.href = "/#/setup-profile";
                      },1000)
                        
                      }  

                    })
              },
      
              logout: function(){
                  this.axios.get('/cx/logout')
                  .then((response) => { 
                    
                    window.location.href = "/#/";
                      
                  }).catch((err) => { 
                    console.log(err)

                  })
              },
             formatAmount: function(amount) {
                return numeral(amount).format('0,0.000000') 
              },
           
              clearError(){
                this.showTotalAmountError = ""
              },
              
               proceedToCheckout(){
                
                 let hasError = false;

                 if(this.totalAmountFiat < 500) {
                   this.showTotalAmountError = "Minimum amount required is equivalent to PHP 500."
                   hasError = true
                   setTimeout(()=>{
                      this.clearError()
                    },4000);
                 }

                 if(!hasError ){
                 let myCartItems = {
                   product: this.cart.product,
                   fromCurrency: this.cart.fromCurrency,
                   toCurrency: this.cart.toCurrency,
                   image: this.cart.image,
                   price: this.cart.price,
                   cryptoToReceive: this.cryptoToReceive,
                   cryptoInput: this.amountToBuy,
                   fee: this.cart.fee,
                   totalFiat: this.totalAmountFiat,
                   userId: this.$route.params.userId,
                   status: this.status
                   
                 };
                 localStorage.setItem('cartItems', JSON.stringify(myCartItems));
                    console.log(myCartItems);
                    
                    if(this.totalAmountFiat < 500 ){
                      this.showTotalAmountError = true
                    }
                    if(this.totalAmountFiat > 500 && this.totalAmountFiat < 499999.99){
                      if(this.status == 'UNVERIFIED'){
                        this.$router.push({name: 'Kyc', params: { userId: this.$route.params.userId}})
                      } 
                    }
                     if(this.totalAmountFiat > 500000){
                      this.showContact = true
                    }
                    
                    else if(this.status === 'VERIFIED'){
                      this.$router.push({name: 'Checkout', params: { userId: this.$route.params.userId}})
                       console.log(this.status)
                    }

                  

                   history.pushState(null, null, location.href);
                    window.onpopstate = function () {
                        history.go(1);
                    };
                 }
              },

             isNumber: function(evt) {
                  evt = (evt) ? evt : window.event;
                  var charCode = (evt.which) ? evt.which : evt.keyCode;
                  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                  } else {
                    return true;
                  }
                },

                preventNav(event) {
                  if (!this.isEditing) return
                  event.preventDefault()
                  event.returnValue = ""
                },
              },
          

          
          beforeMount(){
            this.checkSession();
            window.addEventListener("beforeunload", this.preventNav)
          },
           beforeDestroy() {
            window.removeEventListener("beforeunload", this.preventNav);
          },

          beforeRouteLeave(to, from, next) {
            if (this.isEditing) {
              if (!window.confirm("Leave without saving?")) {
                return;
              }
            }
            next();
          },
          mounted(){
            this.getProfile();
          }
      }

    </script>


    <style scoped>
      .main{
        height: 100vh !important;
        overflow-y: auto;
      }
      .body{
        margin-bottom: 100px !important;
        padding-bottom: 20px;
      }
      .d-modal{
        margin-bottom: 100px !important;
        padding-bottom: 20px;
      }
      .navbar{
        display: flex;
        padding: 20px 100px;
      }
      .product-cart{
        height: auto !important;
        margin-top: 50px;
        margin-bottom: 50px;
    }
      .logo{
          justify-content: center;
          text-align: center;
          display: flex !important;
      }
      .logo img{
          background-color: transparent;
          width: 7rem;
      }
     .store-img{
       width: 7rem;
     }
     .cart-text{
       font-size: 25px !important;
       color: black;
     }
     .error-text{
       font-size: 25px;
       color: red;
     }
     .input-text{
       font-size: 35px;
       color: black;
     }
      .card-cart {
        border: none;
        background: transparent;
        margin: 0 auto;
      }
      .cardCart {
        margin: 0 auto;
        align-content: center;
        text-align: center;
      }
      .amount{
        font-size: 20px;
        font-weight: bolder;
        border-bottom: 1px solid black;
      }

      .row.totalAmount {
        background: rgba(74, 149, 211, 0.938);
      }
      #cart-buttons {
        margin-left: 80%;
        display: flex;
        justify-content: space-around;
        text-decoration: none !important;
      }
      .cancel{
        color: black;
      }
      @media only screen and (max-device-width: 480px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
        .navbar{
          padding: 0 !important;
        }
        .store-img{
          width: 3rem;
        }
        form .text-center, .cart-text, .input-text {
          font-size: 20px;
        }
        .card-body{
          padding: 0 !important;
          margin: 0!important;
        }
        #cart-buttons{
          margin: 0 !important;
          margin-left: 0% !important;
          justify-content: center;
        }

      
      }

    </style>